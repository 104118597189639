import axios from '@/api/axios';
import Vue from 'vue';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    payers: [],
    outstanding: [],
    basket: {},
    comments: {},
    refreshUserPayers: true,
  },
})
  .get({
    action: 'getUserPayers',
    path: 'user/bills/payers',
    onSuccess: (s, { data }) => {
      s.payers = data.sort((a, b) => a.payerName.localeCompare(b.payerName));
      s.refreshUserPayers = false;
    },
  })
  .get({
    action: 'getUserOutstandingBills',
    property: 'outstanding',
    path: 'user/bills/outstanding',
  })
  .get({
    action: 'getUserPaidBills',
    path: 'user/bills/paid',
  })
  .getStore();

store.mutations.toggleBillBasket = (s, bill) => {
  const key = `bill_${bill.payerId}_${bill.billId}`;
  if (s.basket[key]) {
    Vue.delete(s.basket, key);
  } else {
    Vue.set(s.basket, key, bill);
  }
};

const productSetKey = (item) =>
  `productset|${item.payerId}|${item.timeFrame}|${item.availabilityId}|${item.isPaid}|${item.isReturned}`;

store.mutations.addItemBasket = (s, item) => {
  const productSet = s.basket[productSetKey(item)];
  if (!productSet) {
    Vue.set(s.basket, productSetKey(item), {
      payerId: item.payerId,
      productSetName: item.productSetName,
      isReturned: item.isReturned,
      isPaid: item.isPaid,
      productSetId: item.productSetId,
      categoryId: item.categoryId,
      payeeId: item.payeeId,
      timeFrame: item.timeFrame,
      timeFrameName: item.timeFrameName,
      availabilityId: item.availabilityId,
      currency: item.currency,
      value: item.price,
      days: [{
        day: item.day,
        price: item.price,
        count: item.count,
      }],
    });
  } else {
    productSet.value += item.price;

    productSet.days.push({
      day: item.day,
      price: item.price,
      count: item.count,
    });
  }
};

store.mutations.setItemBasket = (s, item) => {
  if (item.count === 0) {
    Vue.delete(s.basket, productSetKey(item));
    return;
  }

  const key = productSetKey(item);

  const productSet = s.basket[key];
  if (!productSet) {
    Vue.set(s.basket, key, {
      payerId: item.payerId,
      productSetName: item.productSetName,
      isReturned: undefined,
      isPaid: undefined,
      productSetId: item.productSetId,
      payeeId: item.payeeId,
      timeFrame: item.timeFrame,
      timeFrameName: item.timeFrameName,
      availabilityId: item.availabilityId,
      currency: item.currency,
      value: item.price,
      days: [{
        day: item.day,
        price: item.price,
        count: item.count,
      }],
    });
  } else {
    const day = productSet.days.find((x) => x.day === item.day);
    if (day) {
      day.price = item.price;
      day.count = item.count;
    } else {
      productSet.days.push({
        day: item.day,
        price: item.price,
        count: item.count,
      });
    }

    productSet.value = productSet.days.reduce((acc, curr) => acc + curr.price, 0);
  }
};

store.mutations.removeItemBasket = (s, item) => {
  const key = productSetKey(item);
  const productSet = s.basket[key];

  if (productSet) {
    const day = productSet.days.find((x) => x.day === item.day);
    productSet.value -= day.price;

    productSet.days = productSet.days.filter((x) => x.day !== item.day);
    if (productSet.days.length === 0) {
      Vue.delete(s.basket, key);
    }
  }
};

store.mutations.addProductSetToBasket = (s, item) => {
  Vue.set(s.basket, productSetKey(item), item);
};

store.mutations.removeProductSetFromBasket = (s, item) => {
  const key = productSetKey(item);
  if (s.basket[key]) {
    Vue.delete(s.basket, key);
  }
};

store.mutations.updateComment = (s, { day, comment }) => {
  Vue.set(s.comments, day, comment);
};
store.mutations.clearComments = (s) => {
  s.comments = {};
};

store.getters = {
  refreshUserPayers: (s) => s.refreshUserPayers,
  userPayers: (s) => s.payers,
  userOutstanding: (s) => s.outstanding,
  itemInBasket: (s) => (item) => s.basket[productSetKey(item)]?.days.some((d) => d.day === item.day),
  billInBasket: (s) => ({ payerId, billId }) => !!s.basket[`bill_${payerId}_${billId}`],
  productSetInBasket: (s) => (set) => (set.availabilityIds || [])
    .reduce((acc, aid) => acc + (s.basket[productSetKey({ ...set, availabilityId: aid })]?.days.length || 0), 0),
  basketGroups: (s) => Object.values(Object.values(s.basket)
    .reduce((acc, curr) => {
      const type = `${curr.billId ? 'bill' : 'shop'}`;
      const group = `${(curr.accountNumber || '-')}_${curr.payeeId}_${curr.currency}_${curr.gatewayId}_${type}`;

      if (acc[group]) {
        acc[group].list.push(curr);
        acc[group].value += curr.value;
      } else {
        acc[group] = {
          key: group,
          type,
          gatewayId: curr.gatewayId,
          accountNumber: curr.accountNumber,
          currency: curr.currency,
          payeeId: curr.payeeId,
          value: curr.value,
          list: [curr],
        };
      }
      return acc;
    }, {})),
};

export default store;
