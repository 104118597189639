import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getUserShop',
    path: '/user/shop/offer',
  })
  .post({
    action: 'createOrder',
    path: '/user/shop/order',
  })
  .post({
    action: 'getCreateOrderPreview',
    path: '/user/shop/order/preview',
  })
  .get({
    action: 'getOrdered',
    path: '/user/shop/orders',
  })
  .get({
    action: 'getOrdersSummary',
    path: '/user/shop/summary',
  })
  .getStore();

export default store;
